import { ADD_BIN_COLLECTION_SUCCESS } from '../../constants/binCollections.constants'
import { functions } from '../firebase'

const createBinCollection = values => {
  return async dispatch => {
    values.bin = values.bin && values.bin.value
    values.test = process.env.NODE_ENV !== 'production' 
    const createBinCollection = functions.httpsCallable('createBinCollection')
    await createBinCollection(values)
    return dispatch({
      type: ADD_BIN_COLLECTION_SUCCESS,
    })
  }
}

export const binCollections = {
  createBinCollection,
}

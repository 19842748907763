import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import { register } from './serviceWorker'
import routes from './routes'
import './style/style.scss'

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router
          basename={process.env.REACT_APP_ROUTER_BASENAME}
          history={createBrowserHistory()}
        >
          <Switch>
            {routes.map((prop, key) => (
              <Route path={prop.path} component={prop.component} key={key} />
            ))}
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
)

register()

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

class ThanksStep extends Component {
  render() {
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1">
            <div className="text-center">
              <h3 className="py-3">thank you for ordering binbags</h3>
              <h5 className="text-secondary py-3">
                you will receive an email shortly confirming your request
              </h5>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

ThanksStep.propTypes = {
  collectionRequestResult: PropTypes.object,
  formSyncErrors: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
}

export default ThanksStep

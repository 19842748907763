import { REPORT_PROBLEM_SUCCESS } from '../../constants/problem.constants'
import { functions } from '../firebase'

const reportProblem = values => {
  return async dispatch => {
    values.object = values.bin && values.bin.value
    values.test = process.env.NODE_ENV !== 'production' 
    const reportProblem = functions.httpsCallable('reportProblem')
    await reportProblem(values)
    return dispatch({
      type: REPORT_PROBLEM_SUCCESS,
      payload: true,
    })
  }
}

export const problem = {
  reportProblem,
}

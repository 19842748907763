import React, { Component } from 'react'
import { InputGroup, Input, FormFeedback } from 'reactstrap'

class CustomTextInput extends Component {
  render() {
    const {
      meta: { error } = {},
      input: { ...inputProps },
      ...props
    } = this.props
    return (
      <InputGroup className="pb-3">
        <Input {...inputProps} {...props} />
        <FormFeedback tooltip>{error}</FormFeedback>
      </InputGroup>
    )
  }
}

export default CustomTextInput

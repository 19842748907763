import { auth as authAPI } from '../api/modules/auth.api'
import { firebase } from '../api/firebase'
import { pick } from 'lodash'
import {
  LOGIN_WITH_CODE_SUCCESS,
  SET_USER_PROFILE,
  SET_CUSTOM_USER_CLAIMS,
  LOGOUT,
  LOADING_PROFILE_REQUEST,
  LOADING_PROFILE_SUCCEED,
} from '../constants/auth.constants'

export const auth = {
  onAuthStateChanged: callback => firebase.auth().onAuthStateChanged(callback),
  getUserProfile(user) {
    return async dispatch => {
      dispatch({
        type: LOADING_PROFILE_REQUEST,
      })
      const {
        userSnap,
        userData,
        business,
        binLocations,
      } = await authAPI.getUserProfile(user)
      dispatch({
        type: SET_USER_PROFILE,
        payload: {
          user: {
            path: userSnap.ref.path,
            ...pick(userData, ['id', 'displayName', 'email', 'role']),
          },
          business,
          binLocations,
        },
      })
      const idTokenResult = await authAPI.getCustomUserClaims()
      dispatch({
        type: SET_CUSTOM_USER_CLAIMS,
        payload: idTokenResult.claims,
      })
      return dispatch({
        type: LOADING_PROFILE_SUCCEED,
      })
    }
  },
  loginWithCode(code) {
    return async dispatch => {
      await dispatch(authAPI.loginWithCode(code))
    }
  },
  loginAutomatically() {
    return dispatch => {
      return dispatch({
        type: LOGIN_WITH_CODE_SUCCESS,
      })
    }
  },
  logout() {
    return async dispatch => {
      await firebase.auth().signOut()
      return dispatch({
        type: LOGOUT,
      })
    }
  },
  setUser(user) {
    return dispatch => {
      dispatch({
        type: SET_USER_PROFILE,
        payload: user,
      })
    }
  },
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Row, Col } from 'reactstrap'
import { Field, propTypes as reduxFormPropTypes } from 'redux-form'
import Calendar from 'rc-calendar'
import 'rc-calendar/assets/index.css'
import enGB from 'rc-calendar/lib/locale/en_GB'
import 'moment/locale/en-gb'

import { getNextBestDayForCollection } from '../../utils'

class SelectDateStep extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    dispatch: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
    business: PropTypes.object,
    formSyncErrors: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    collectionRequestAmount: PropTypes.number,
    dismissError: PropTypes.func.isRequired,
  }
  state = {
    gettingQuote: false,
  }
  renderCalendar = ({ input: { onChange } }) => {
    const onCalendarChange = date => {
      onChange(date.toDate().toISOString())
    }
    return (
      <div>
        <Calendar
          onChange={onCalendarChange}
          format="DD-MM-YYYY"
          locale={enGB}
          disabledDate={getNextBestDayForCollection}
          className="text-center my-5 mx-auto"
        />
      </div>
    )
  }
  changeStep = async () => {
    try {
      this.setState({ gettingQuote: true })
      await this.props.getCollectionRequestAmount({
        bin: this.props.formValues.bin,
      })
      this.props.change('amount', this.props.collectionRequestAmount)
      this.props.changeStep(this.props.step + 1)
    } catch (err) {
      this.props.showError('There was a problem getting a quote for you')
    }
  }
  render() {
    const {
      bin,
      business,
      formSyncErrors: { requested_pickup_date: requestedPickupDateError },
      dismissError,
    } = this.props
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 text-center">
            <h3>select day of your collection</h3>
            <p>{business.name}</p>
          </Col>
        </Row>
        <Row className="">
          <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <Field
              id="requested_pickup_date"
              name="requested_pickup_date"
              component={this.renderCalendar}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Button
              color="primary"
              className="px-5"
              onClick={this.changeStep}
              disabled={Boolean(requestedPickupDateError)}
              block
            >
              {this.state.gettingQuote ? (
                <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
              ) : (
                'confirm'
              )}
            </Button>
          </Col>
        </Row>
        <Alert
          color="danger"
          isOpen={bin.error}
          toggle={() => dismissError()}
          className="fixed-bottom m-5"
        >
          {bin.errorMessage}
        </Alert>
      </>
    )
  }
}

export default SelectDateStep

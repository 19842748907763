export const GET_BINS_PROCESSING = 'GET_BINS_PROCESSING'
export const GET_BINS_SUCCEED = 'GET_BINS_SUCCEED'
export const GET_BINS_FAILED = 'GET_BINS_FAILED'
export const GET_BIN_PROCESSING = 'GET_BIN_PROCESSING'
export const GET_BIN_SUCCEED = 'GET_BIN_SUCCEED'
export const GET_BIN_FAILED = 'GET_BIN_FAILED'
export const SAVE_BIN_PROCESSING = 'SAVE_BIN_PROCESSING'
export const SAVE_BIN_SUCCEED = 'SAVE_BIN_SUCCEED'
export const SAVE_BIN_FAILED = 'SAVE_BIN_FAILED'
export const SCANNED_BIN_QR_CODE = 'SCANNED_BIN_QR_CODE'
export const RESET_BIN_QR_CODE = 'RESET_BIN_QR_CODE'
export const GET_COLLECTION_REQUEST_AMOUNT_SUCCEED =
  'GET_COLLECTION_REQUEST_AMOUNT_SUCCEED'
export const REQUEST_COLLECTION_SUCCEED = 'REQUEST_COLLECTION_SUCCEED'
export const SUBMIT_OBSERVATION_SUCCEED = 'SUBMIT_OBSERVATION_SUCCEED'
export const SHOW_BIN_ERROR = 'SHOW_BIN_ERROR'
export const DISMISS_BIN_ERROR = 'DISMISS_BIN_ERROR'

import { binCollections as binCollectionsAPI } from '../api'

export const binCollections = {
  createBinCollection(request) {
    return dispatch => {
      return dispatch(binCollectionsAPI.createBinCollection(request))
    }
  },
  getCollectionRequestAmount(request) {
    return dispatch => {
      return dispatch(binCollectionsAPI.getCollectionRequestAmount(request))
    }
  },
}

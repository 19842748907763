import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Navbar, NavbarBrand } from 'reactstrap'

class HeaderMinimal extends Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
    children: PropTypes.element,
    history: PropTypes.object,
  }
  static defaultProps = {
    link: '',
  }
  state = {
    isOpen: false,
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { link, children } = this.props
    return (
      <Container fluid className="bg-light border-bottom border-white">
        <Navbar light expand="md" className="sticky-top text-center">
          <NavbarBrand
            onClick={() => {
              this.props.history.push(link)
            }}
            className="mx-auto"
          >
            {children}
          </NavbarBrand>
        </Navbar>
      </Container>
    )
  }
}

export default HeaderMinimal

import { firebase, db, functions } from '../firebase'
import { pick } from 'lodash'
import {
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_WITH_CODE_REQUEST,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  LOGIN_WITH_CODE_SUCCESS,
} from '../../constants/auth.constants'
import { collections } from '../../constants'

export const auth = {
  onAuthStateChanged: firebase.auth().onAuthStateChanged,
  getCustomUserClaims: () => firebase.auth().currentUser.getIdTokenResult(),
  loginWithCode(code) {
    return async dispatch => {
      dispatch({
        type: LOGIN_WITH_CODE_REQUEST,
      })
      const loginWithCode = functions.httpsCallable('loginWithCode')
      const result = await loginWithCode({ code })
      const token = result.data.token
      await firebase.auth().signInWithCustomToken(token)
      dispatch({
        type: LOGIN_WITH_CODE_SUCCESS
      })
    }
  },
  login(values) {
    return async dispatch => {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
      } catch (err) {
        dispatch({
          type: LOGIN_FAILURE,
          meta: {},
          payload: err,
        })
      }
    }
  },
  logout() {
    return async dispatch => {
      await firebase.auth().signOut()
      dispatch({
        type: LOGOUT,
      })
    }
  },
  signup(user) {
    return async dispatch => {
      dispatch({
        type: SIGNUP_REQUEST,
      })
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(user.email, user.password)
        var newUser = firebase.auth().currentUser
        dispatch({
          type: SIGNUP_SUCCESS,
          meta: {},
          payload: {
            user: newUser,
          },
        })
      } catch (err) {
        dispatch({
          type: SIGNUP_FAILURE,
        })
      }
    }
  },
  requestPasswordReset(email) {
    return async dispatch => {
      dispatch({
        type: PASSWORD_RESET_REQUEST,
      })
      try {
        await firebase.auth().sendPasswordResetEmail(email)
        dispatch({
          type: PASSWORD_RESET_SUCCESS,
          meta: {},
        })
      } catch (err) {
        dispatch({
          type: PASSWORD_RESET_FAILURE,
          payload: err,
        })
      }
    }
  },
  async getUserProfile(user) {
    const userSnap = await db
      .collection(collections.users)
      .doc(user.uid)
      .get()
    const userData = userSnap.data()
    userData.id = user.uid
    let business = null
    let binLocations = []
    if (userData.business) {
      const businessSnap = await userData.business.get()
      business = {
        id: businessSnap.id,
        ...pick(businessSnap.data(), ['name', 'has_account']),
      }
      const binLocationSnaps = await db
        .collection(collections.bin_locations)
        .where('businesses', 'array-contains', businessSnap.ref)
        .get()
      if (binLocationSnaps.docs.length > 0) {
        for (const binLocationSnap of binLocationSnaps.docs) {
          const binLocationData = binLocationSnap.data()

          binLocations.push({
            id: binLocationSnap.id,
            ...pick(binLocationData, ['loc']),
          })
        }
      }
    }
    return { userSnap, userData, business, binLocations }
  },
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table, Button } from 'reactstrap'
import { propTypes as reduxFormPropTypes } from 'redux-form'
import moment from 'moment'

class ThanksStep extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    history: PropTypes.object.isRequired,
    collectionRequestResult: PropTypes.object,
    formSyncErrors: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
  }

  render() {
    const { collectionRequestResult } = this.props
    const requestedPickupDate = new Date(
      collectionRequestResult.requested_pickup_date
    )
    const requestedPickupDateFormatted = moment(requestedPickupDate).format(
      'dddd, MMMM Do YYYY'
    )
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1">
            <div className="text-center">
              <h3 className="py-3">thank you for ordering a collection</h3>
              <h5 className="text-secondary py-3">
                you will receive an email shortly confirming your request
              </h5>
            </div>
            <div className="border border-dark py-3 bg-light">
              <div className="p-3">
                <h4 className="text-info">order summary</h4>
              </div>
              <Table>
                <tbody>
                  <tr>
                    <th>
                      <p className="text-muted">Bin</p>
                    </th>
                    <td>
                      <p>{collectionRequestResult.bin}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="text-muted">Date</p>
                    </th>
                    <td>
                      <p>{requestedPickupDateFormatted}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="text-muted">Collection address</p>
                    </th>
                    <td>
                      <p>{collectionRequestResult.address}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="text-muted">Total</p>
                    </th>
                    <td>
                      <p>{collectionRequestResult.amount}</p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="primary"
              className="px-5"
              onClick={() => this.props.history.push('/dashboard')}
              block
            >
              {'dashboard'}
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default ThanksStep

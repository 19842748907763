export * from './auth.constants'
export * from './bin.constants'
export * from './problem.constants'
export * from './binbag.constants'
export * from './binCollections.constants'

export const allowedRoutes = ['login', 'signup', 'request-password-reset']

export const allowedActions = {
  admin: [
    'collection_requests',
    'bin_collections',
    'binbag_requests',
    'problems',
    'observations',
  ],
  staff: ['problems', 'observations'],
  customer: [
    'collection_requests',
    'binbag_requests',
    'problems',
    'observations',
  ],
  collector: ['bin_collections', 'problems', 'observations'],
  dev: ['']
}

export const collections = {
  bin_collections: 'bin_collections',
  bin_locations: 'bin_locations',
  binbag_requests: 'binbag_requests',
  binbag_rolls: 'binbag_rolls',
  bins: 'bins',
  businesses: 'businesses',
  collection_requests: 'collection_requests',
  comments: 'comments',
  observations: 'observations',
  one_off_collection_requests: 'one_off_collection_requests',
  problems: 'problems',
  quote_requests: 'quote_requests',
  roles: 'roles',
  users: 'users',
}

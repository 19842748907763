import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap'

class Header extends Component {
  static propTypes = {
    link: PropTypes.string,
    children: PropTypes.element,
  }
  state = {
    isOpen: false,
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { link, children } = this.props
    return (
      <Container>
        <Navbar color="white" light expand="md" className="sticky-top">
          <NavbarBrand href={link}>{children}</NavbarBrand>
          <NavbarToggler onClick={this.toggle} className="border-0" />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto binit-header-font binit-navbar" navbar>
              <NavItem className="mr-lg-5">
                <NavLink href="/services/">services</NavLink>
              </NavItem>
              <NavItem className="mr-lg-5">
                <NavLink href="/about-us/">about-us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    )
  }
}

export default Header

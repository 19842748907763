import {
  GET_BINBAG_FAILED,
  GET_BINBAG_SUCCEED,
  GET_BINBAG_PROCESSING,
  REQUEST_BINBAGS_SUCCEED,
  GET_BINBAG_ORDER_AMOUNT_SUCCEED,
} from '../../constants/binbag.constants'
import { db, functions } from '../firebase'

import { collections } from '../../constants'

const dbCollection = collections.binbag_rolls

const getByName = name => {
  return async dispatch => {
    dispatch({
      type: GET_BINBAG_PROCESSING,
    })
    const binbagsRef = db.collection(dbCollection)
    const binBagSnap = binbagsRef.where('name', '==', name).limit(1)
    let binBagDocs
    try {
      binBagDocs = await binBagSnap.get()
    } catch (err) {
      return dispatch({
        type: GET_BINBAG_FAILED,
        payload: {
          error: `Could not get bin bag`,
        },
      })
    }
    if (binBagDocs.docs.length > 0) {
      let data = binBagDocs.docs[0].data()
      return dispatch({
        type: GET_BINBAG_SUCCEED,
        payload: {
          record: {
            id: binBagDocs.docs[0].id,
            name: data.name,
            type: data.type,
            quantity: data.quantity,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BINBAG_FAILED,
        payload: {
          error: `No such bin bag: ${name}`,
        },
      })
    }
  }
}

const requestBinbags = values => {
  return async dispatch => {
    values.test = process.env.NODE_ENV !== 'production'
    const requestBinbags = functions.httpsCallable('requestBinbags')
    const result = await requestBinbags(values)
    return dispatch({
      type: REQUEST_BINBAGS_SUCCEED,
      payload: result.data,
    })
  }
}

export const getBinbagOrderAmount = values => {
  return async dispatch => {
    values.test = process.env.NODE_ENV !== 'production'
    const getBinbagOrderAmount = functions.httpsCallable('getBinbagOrderAmount')
    const result = await getBinbagOrderAmount(values)
    return dispatch({
      type: GET_BINBAG_ORDER_AMOUNT_SUCCEED,
      payload: result.data,
    })
  }
}

export const binbag = {
  getByName,
  requestBinbags,
  getBinbagOrderAmount,
}

import {
  GET_BINS_SUCCEED,
  GET_BIN_FAILED,
  GET_BIN_SUCCEED,
  GET_BIN_PROCESSING,
  REQUEST_COLLECTION_SUCCEED,
  SUBMIT_OBSERVATION_SUCCEED,
  GET_COLLECTION_REQUEST_AMOUNT_SUCCEED,
  GET_BINS_PROCESSING,
} from '../../constants/bin.constants'
import { db, functions } from '../firebase'
import * as utils from '../../utils'
import { collections } from '../../constants'

const dbCollection = 'bins'

const listForBinLocations = (binLocations, binQRCode) => {
  return async dispatch => {
    dispatch({
      type: GET_BINS_PROCESSING,
    })
    let bins = []
    for (const binLocation of binLocations) {
      // get the bin location ref from the id we stored
      const binLocationRef = db
        .collection(collections.bin_locations)
        .doc(binLocation.id)
      const binsSnaps = await db
        .collection(dbCollection)
        .where('bin_location', '==', binLocationRef)
        .get()
      if (binsSnaps.docs.length > 0) {
        for (const binSnap of binsSnaps.docs) {
          const binData = await utils.processSnapshotData(
            binSnap.data(),
            collections.bins
          )
          binData.id = binSnap.id
          bins.push(binData)
        }
      }
    }
    if (binQRCode) {
      const binSnaps = await db
        .collection(dbCollection)
        .where('name', '==', binQRCode)
        .limit(1)
        .get()
      if (binSnaps.docs.length === 0) {
        dispatch({
          type: GET_BIN_FAILED,
          payload: `Could not find bin '${binQRCode}'`,
        })
      } else {
        const binData = await utils.processSnapshotData(
          binSnaps.docs[0].data(),
          collections.bins
        )
        binData.id = binSnaps.docs[0].id
        bins.unshift(binData)
        // dispatch({ type: RESET_BIN_QR_CODE })
      }
    }
    return dispatch({
      type: GET_BINS_SUCCEED,
      payload: {
        list: bins,
      },
    })
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_BIN_PROCESSING,
    })
    const binsRef = db.collection(dbCollection)
    const binSnap = binsRef.doc(id)
    const binDoc = await binSnap.get()
    if (binDoc.exists) {
      const binData = binDoc.data()
      let bin_location = {}
      if (binData.bin_location) {
        const binLocationSnap = await binData.bin_location.get()
        if (binLocationSnap.exists) {
          const binLocationData = binLocationSnap.data()
          bin_location = {
            id: binLocationSnap.id,
            name: binLocationData.name,
          }
        }
      }
      return dispatch({
        type: GET_BIN_SUCCEED,
        payload: {
          record: {
            id: binDoc.id,
            name: binData.name,
            ...binData,
            bin_location,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BIN_FAILED,
        payload: {
          error: `No such bin: ${id}`,
        },
      })
    }
  }
}

const getByName = name => {
  return async dispatch => {
    dispatch({
      type: GET_BIN_PROCESSING,
    })
    const binsRef = db.collection(dbCollection)
    const binDocs = await binsRef
      .where('name', '==', name)
      .limit(1)
      .get()
    if (binDocs.docs.length === 0) {
      dispatch({
        type: GET_BIN_FAILED,
        payload: `Could not get bin`,
      })
    }
    let binData = binDocs.docs[0].data()
    let bin_location = {}
    if (binData.bin_location) {
      const binLocationSnap = await binData.bin_location.get()
      if (binLocationSnap.exists) {
        const binLocationData = binLocationSnap.data()
        bin_location = {
          id: binLocationSnap.id,
          name: binLocationData.name,
        }
      }
      binData = await utils.processSnapshotData(binData, collections.bins)
      return dispatch({
        type: GET_BIN_SUCCEED,
        payload: {
          record: {
            id: binDocs.docs[0].id,
            name: binData.name,
            type: binData.type,
            bin_location,
          },
        },
      })
    } else {
      return dispatch({
        type: GET_BIN_FAILED,
        payload: `No such bin: ${name}`,
      })
    }
  }
}

const requestCollection = values => {
  return async dispatch => {
    values.bin = values.bin && values.bin.value
    values.test = process.env.NODE_ENV !== 'production'
    const requestCollection = functions.httpsCallable('requestCollection')
    const result = await requestCollection(values)
    return dispatch({
      type: REQUEST_COLLECTION_SUCCEED,
      payload: result.data,
    })
  }
}

const getCollectionRequestAmount = values => {
  return async dispatch => {
    values.bin = values.bin && values.bin.value
    const getCollectionRequestAmount = functions.httpsCallable(
      'getCollectionRequestAmount'
    )
    const result = await getCollectionRequestAmount(values)
    return dispatch({
      type: GET_COLLECTION_REQUEST_AMOUNT_SUCCEED,
      payload: result.data,
    })
  }
}

const submitObservation = values => {
  return async dispatch => {
    values.bin = values.bin && values.bin.value
    values.test = process.env.NODE_ENV !== 'production'
    const createObservation = functions.httpsCallable('createObservation')
    const result = await createObservation(values)
    return dispatch({
      type: SUBMIT_OBSERVATION_SUCCEED,
      paylod: result.data,
    })
  }
}

export const bin = {
  listForBinLocations,
  get,
  getByName,
  requestCollection,
  getCollectionRequestAmount,
  submitObservation,
}

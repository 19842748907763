import { Component } from 'react'
import PropTypes from 'prop-types'

class Wizard extends Component {
  render() {
    const { step, steps } = this.props
    return steps[step - 1]
  }
}

Wizard.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
}

Wizard.defaultProps = {
  step: 1,
}

export default Wizard

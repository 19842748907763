import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  SubmissionError,
  getFormValues,
  getFormSyncErrors,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import { Container, Alert } from 'reactstrap'

import Wizard from './Wizard'
import SelectBinStep from './SelectBinStep'
import RecordFullness from './record-bin-fullness/RecordFullness'
import ThanksStep from './record-bin-fullness/ThanksStep'

import { bin as binActions } from '../actions/bin.actions'
import { flow as flowActions } from '../actions/flow.actions'

const form = 'record-bin-fullness'

const validate = values => {
  const errors = {}
  if (!values.bin) {
    errors.bin = 'Required'
  }
  if (values.fullness === null) {
    errors.fullness = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    const observation = {
      ...values,
      // have to add in user
      user: props.user.id,
    }
    await props.submitObservation(observation)
    props.changeStep(3)
  } catch (err) {
    setTimeout(props.hideError, 6000)
    throw new SubmissionError({
      _error: 'Problem creating observation, please try again later',
    })
  }
}

/**
 * RECORD BIN FULLNESS
 *
 * Record bin fullness flow
 */
class RecordBinFullness extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    step: PropTypes.number.isRequired,
    collectionRequestResult: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
    binLocations: PropTypes.array.isRequired,
    business: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    formSyncErrors: PropTypes.object.isRequired,
    error: PropTypes.string,
    errorMessage: PropTypes.string,
    dismissError: PropTypes.func.isRequired,
  }
  state = {
    alertOpen: false,
    stripe: null,
  }
  componentDidMount() {
    this.props.changeStep(1)
  }
  handleClickAction = link => {
    this.props.history.push(link)
  }
  render() {
    const {
      handleSubmit,
      step,
      error,
      submitFailed,
    } = this.props
    return (
      <Container>
        <form onSubmit={handleSubmit}>
          <Wizard
            step={step}
            steps={[
              <SelectBinStep {...this.props} key={1} />,
              <RecordFullness {...this.props} key={2} />,
              <ThanksStep {...this.props} key={3} />,
            ]}
          />
        </form>
        <Alert
          color="primary"
          isOpen={submitFailed}
          // @TODO: fix error dismissal
          toggle={() => {}}
          className="fixed-bottom m-5"
        >
          {error}
        </Alert>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const { auth, bin, flow } = state
  return {
    step: flow.recordBinFullnessStep,
    user: auth.user,
    business: auth.business,
    binLocations: auth.binLocations,
    bin,
    claims: auth.claims,
    formValues: getFormValues(form)(state),
    formSyncErrors: getFormSyncErrors(form)(state),
    initialValues: {
      bin: {
        name: bin.record ? bin.record.name || '' : '',
        value: bin.record ? bin.record.id || '' : '',
      },
      fullness: 0,
      notes: '',
    },
  }
}

const mapDispatchToProps = {
  changeStep: flowActions.recordBinFullnessChangeStep,
  ...binActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: false,
  })(RecordBinFullness)
)

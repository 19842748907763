export const REQUEST_API = 'REQUEST_API'
export const RESET_ERROR = 'RESET_ERROR'
export const LOGIN_WITH_CODE_REQUEST = 'LOGIN_WITH_CODE_REQUEST'
export const LOGIN_WITH_CODE_SUCCESS = 'LOGIN_WITH_CODE_SUCCESS'
export const LOGIN_WITH_CODE_FAILURE = 'LOGIN_WITH_CODE_FAILURE'
export const SET_CUSTOM_USER_CLAIMS = 'SET_CUSTOM_USER_CLAIMS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'

export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const LOADING_PROFILE_REQUEST = 'LOADING_PROFILE_REQUEST'
export const LOADING_PROFILE_SUCCEED = 'LOADING_PROFILE_SUCCEED'
export const LOADING_PROFILE_FAILURE = 'LOADING_PROFILE_FAILURE'

export const LOGOUT = 'LOGOUT'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'reactstrap'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { formatMoney } from 'accounting'

class PaymentStep extends Component {
  state = {
    canMakePayment: true,
    card: null,
  }
  static propTypes = {
    stripe: PropTypes.object,
    business: PropTypes.object,
    binbagOrderAmount: PropTypes.number.isRequired,
    formSyncErrors: PropTypes.object.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    showError: PropTypes.func,
  }
  handlePayment = async () => {
    try {
      const card = await this.props.stripe.createSource({ type: 'card' })
      if (card.error) {
        this.props.showError(card.error.message)
      } else {
        const result = await this.props.stripe.createToken(card)
        if (result.error) {
          this.props.showError(result.error.message)
        }
        if (process.env.NODE_ENV !== 'production') {
          this.props.change('test', true)
          this.props.change('amount', 100)
        }
        this.props.change('source', result.token.id)
        this.props.change('on_account', false)
        this.props.handleSubmit()
      }
    } catch (err) {
      this.props.showError(
        'There was a problem processing your payment, please try again'
      )
    }
  }
  handleOnAccountSubmit = () => {
    if (process.env.NODE_ENV !== 'production') {
      this.props.change('test', true)
    }
    this.props.change('on_account', true)
    this.props.handleSubmit()
  }
  render() {
    const {
      business,
      binbagOrderAmount,
      invalid,
      submitting,
      pristine,
    } = this.props
    const quoteAmountFormatted =
      typeof binbagOrderAmount === 'number'
        ? formatMoney(binbagOrderAmount / 100, '£')
        : 'N/A'
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
            <h3>payment: {quoteAmountFormatted}</h3>
            <p>{business.name}</p>
          </Col>
        </Row>
        <Row className="px-1 px-md-5 pt-3">
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 6, offset: 3 }}
            className="text-center p-3"
          >
            {business.has_account ? (
              <>
                <Button
                  onClick={this.handleOnAccountSubmit}
                  color="primary"
                  disabled={invalid || submitting || pristine}
                  block
                >
                  {submitting ? (
                    <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
                  ) : (
                    'add to my monthly invoice'
                  )}
                </Button>
                <h5 className="pt-3">OR</h5>
              </>
            ) : (
              ''
            )}
            <div className="pt-3">
              <h5>complete order</h5>
              <p>card details</p>
              <div className="pt-3 form-group form-control">
                <CardElement style={{ base: { fontSize: '20px' } }} />
              </div>
              <Button
                onClick={this.handlePayment}
                color="primary"
                disabled={
                  invalid ||
                  submitting ||
                  pristine ||
                  !this.state.canMakePayment
                }
                block
              >
                {submitting ? (
                  <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
                ) : (
                  'complete order'
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

export default injectStripe(PaymentStep)

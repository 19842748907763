import {
  SET_REDIRECT,
  REQUEST_COLLECTION_CHANGE_STEP,
  RECORD_BIN_FULLNESS_CHANGE_STEP,
} from '../constants/flow.constants'

export const flow = {
  setRedirect(path) {
    return dispatch =>
      dispatch({
        type: SET_REDIRECT,
        payload: path,
      })
  },
  requestCollectionChangeStep(step) {
    return dispatch =>
      dispatch({
        type: REQUEST_COLLECTION_CHANGE_STEP,
        payload: step,
      })
  },
  recordBinFullnessChangeStep(step) {
    return dispatch =>
      dispatch({
        type: RECORD_BIN_FULLNESS_CHANGE_STEP,
        payload: step,
      })
  },
}

import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'

class ErrorStep extends Component {
  render() {
    const {
      changeStep,
      history,
    } = this.props
    return (
      <Row>
        <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
          <h3>Eh, something went wrong</h3>
        </Col>
        <Col
          md={{ size: 6, offset: 3 }}
          lg={{ size: 4, offset: 4 }}
          className="text-center"
          style={{marginTop: '100px'}}
        >
          <Button
            color="primary"
            className="px-5"
            onClick={() => changeStep(1)}
            block
          >
            {'Try again'}
          </Button>
          <Button
            color="secondary"
            className="px-5"
            onClick={() => history.push('/dashboard')}
            block
          >
            {'Back to dashboard'}
          </Button>
        </Col>
      </Row>
    )
  }
}

ErrorStep.propTypes = {
  history: PropTypes.object.isRequired,
  changeStep: PropTypes.func.isRequired,
}

export default ErrorStep

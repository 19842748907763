import Dashboard from '../components/Dashboard'
import RequestCollection from '../components/RequestCollection'
import RecordBinFullness from '../components/RecordBinFullness'
import ReportProblem from '../components/ReportProblem'
import ConfirmSuccessfullCollection from '../components/ConfirmSuccessfullCollection'
import OrderBinBags from '../components/OrderBinBags'

const appRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/request-a-collection',
    component: RequestCollection,
  },
  {
    path: '/record-bin-fullness',
    component: RecordBinFullness,
  },
  {
    path: '/report-a-problem',
    component: ReportProblem,
  },
  {
    path: '/confirm-successfull-collection',
    component: ConfirmSuccessfullCollection,
  },
  {
    path: '/order-binbags',
    component: OrderBinBags,
  },
  {
    redirect: true,
    path: '/',
    to: '/dashboard',
  },
]

export default appRoutes

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'reactstrap'
import { Field, propTypes as reduxFormPropTypes } from 'redux-form'

import ChunkyRadio from './inputs/ChunkyRadio'
import DidYouKnow from './DidYouKnow'

class SelectBinStep extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    bin: PropTypes.object.isRequired,
    step: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
    business: PropTypes.object,
    change: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    formSyncErrors: PropTypes.object,
  }

  render() {
    const {
      invalid,
      bin,
      step,
      changeStep,
      business,
      submitting,
      formSyncErrors: { bin: binError },
    } = this.props
    const flags = {}
    const options = bin.list
      .filter(bin => {
        if (!bin) return false
        if (flags[bin.id]) return false
        flags[bin.id] = true
        return true
      })
      .map(bin => {
        return {
          name: bin.name,
          value: bin.id,
          icon: {
            fas: true,
            'fa-dumpster': true,
            'fa-lg': true,
          },
        }
      })
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
            <h3>choose your bin</h3>
            <p>{business && business.name}</p>
          </Col>
        </Row>
        <Row>
          {bin.fetching ? (
            <Col xs="12" className="text-center">
              <i className="fas fa-circle-notch fa-spin fa-5x" />
            </Col>
          ) : (
            ''
          )}
          {bin.fetched && bin.list.length === 0 && (
            <Col xs="12" className="pt-5 text-center">
              <h4>Sorry, no bins found</h4>
            </Col>
          )}
          <Col
            lg={{ size: 8, offset: 2 }}
            className="p-3 p-md-5 pt-3 text-center"
          >
            <Field
              id="bin"
              name="bin"
              component={ChunkyRadio}
              options={options}
              color="light"
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Button
              color="primary"
              className="px-5"
              onClick={() => changeStep(step + 1)}
              disabled={invalid || Boolean(binError)}
              block
            >
              {submitting ? (
                <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
              ) : (
                'confirm'
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="pt-5"
          >
            <DidYouKnow />
          </Col>
        </Row>
      </>
    )
  }
}

export default SelectBinStep

import {
  allowedActions,
  allowedRoutes,
  collections as dbCollections,
} from '../constants'
import moment from 'moment'
import { isEmpty, pick } from 'lodash'

export const allowedRoutesRegexp = new RegExp(allowedRoutes.join('|'))

/**
 * SHORTEN ADDRESS
 * @param {object} address An address object to parse
 * @param {number} trunc An amount to truncate the address by
 */
export const shortenAddress = (address, trunc = 100) => {
  address = address
    ? Object.values(address)
        .filter(val => val !== '')
        .join(', ')
    : ''
  if (address.length > trunc && address.length > 0)
    address = address.substring(0, trunc) + '…'
  return address
}

/**
 * PAGINATION
 *
 * Construct pagination for a table
 *
 * @param {FirestoreQuery} query The firestore query
 * @param {FirestoreCollection} collectionRef The reference to the firestore collection
 * @param {object} pagination An object specifying all the parameters for pagination
 */
export const paginate = async (query, collectionRef, pagination) => {
  if (pagination && pagination.id) {
    let doc = {}
    try {
      doc = await collectionRef.doc(pagination.id).get()
    } catch (err) {
      throw new Error(err)
    }
    if (pagination.direction === -1) {
      return query.endBefore(doc)
    } else {
      return query.startAfter(doc)
    }
  } else return query
}

/**
 * FILTER ACTIONS BY CLAIMS
 *
 * Get the actions this user can perform
 *
 * @param {object} actions A set of potential actions the client can perform
 * @param {object} claims The claims belonging to this user
 */
export const filterActionsByClaims = (actions, claims) => {
  return actions.filter(action => {
    // show all actions for devs
    if (claims.role && claims.role === 'dev') return true
    if (claims.role && claims.role === 'admin') return true
    return (
      claims.role &&
      allowedActions[claims.role] &&
      allowedActions[claims.role].indexOf(action.value) > -1
    )
  })
}

/**
 * GET NEXT BEST DAY FOR COLLECTION
 *
 * Checks a date to see when the next best day for a collection would be
 *
 * @param {moment} date The date to be checked
 */
export const getNextBestDayForCollection = date => {
  if (!date) {
    // allow empty select
    return false
  }
  const day = date.day()
  if (day === 6 || day === 0) {
    return true
  }
  // set the date to be from midday
  date.hour(12)
  date.minute(0)
  date.second(0)
  const now = moment()
  // set the time to 0 so we can compare absolute days
  // if the day is between Mon (1) and Fri (5), offer the next working day
  if (now.day() >= 1 && now.day() <= 5) {
    now.day(now.day() + 1)
  } else if (now.day() === 6) {
    // it's Sat (6)
    now.hour(0)
    now.day(9) // set the day as _next_ tuesday
  } else {
    // it's Sun (0)
    now.hour(0)
    now.day(2) // set the day to next Tuesday
  }
  return date.valueOf() < now.valueOf() // cannot select days before today
}

export const collections = [
  {
    name: dbCollections.bin_locations,
    fields: ['loc'],
    transform: {
      loc: dataToIndex => {
        convertToGeoLoc(dataToIndex, 'loc')
      },
    },
  },
  {
    name: dbCollections.bins,
    fields: ['size', 'loc', 'lockable', 'name', 'type'],
    transform: {
      loc: dataToIndex => {
        convertToGeoLoc(dataToIndex, 'loc')
      },
    },
  },
  {
    name: dbCollections.collection_requests,
    fields: [
      'bin',
      'on_account',
      'paid_status',
      'requested_pickup_date',
      'scheduled_pickup_date',
      'created_at',
    ],
    transform: {
      bin: async dataToIndex => {
        let _geoloc = {}
        if (dataToIndex.hasOwnProperty('bin')) {
          const binRef = await dataToIndex.bin.get()
          if (binRef.exists) {
            const binData = binRef.data()
            dataToIndex.loc = binData.loc
            // also add in **bin type**
            if (binData.hasOwnProperty('type')) {
              dataToIndex.type = binData.type
            }
            // just pass to our conversion fn
            convertToGeoLoc(dataToIndex, 'loc')
          }
          delete dataToIndex.bin
        }
        // also add in **business name**
        if (dataToIndex.hasOwnProperty('business')) {
          const businessRef = dataToIndex.business.get()
          delete dataToIndex.business
          if (businessRef.exists) {
            const businessData = businessRef.data()
            if (businessData.hasOwnProperty('name')) {
              dataToIndex.business = businessData.name
            }
          }
        }
        if (!isEmpty(_geoloc)) {
          dataToIndex._geoloc = _geoloc
        }
      },
      requested_pickup_date: dataToIndex => {
        convertToUnixSeconds(dataToIndex, 'requested_pickup_date')
      },
      scheduled_pickup_date: dataToIndex => {
        convertToUnixSeconds(dataToIndex, 'scheduled_pickup_date')
      },
      created_at: dataToIndex => {
        convertToUnixSeconds(dataToIndex, 'created_at')
      },
    },
  },
  {
    name: dbCollections.quote_requests,
    fields: [
      'business_size',
      'business_type',
      'email',
      'other_business_type',
      'postcode',
      'status',
      'tel',
      'created_at',
    ],
    transform: {
      other_business_type: dataToIndex => {
        if (!dataToIndex.business_type && dataToIndex.other_business_type) {
          dataToIndex.business_type = dataToIndex.other_business_type
        }
      },
      created_at: dataToIndex => {
        convertToUnixSeconds(dataToIndex, 'created_at')
      },
    },
  },
  {
    name: dbCollections.binbag_requests,
    fields: [
      'binbag_roll',
      'on_account',
      'paid_status',
      'status',
      'created_at',
    ],
    transform: {
      binbag_roll: async dataToIndex => {
        const binbagRollRef = await dataToIndex.binbag_roll.get()
        if (binbagRollRef.exists) {
          const binbagRollData = binbagRollRef.data()
          dataToIndex.quantity = binbagRollData.quantity
          dataToIndex.type = binbagRollData.type
        }
      },
      created_at: dataToIndex => {
        convertToUnixSeconds(dataToIndex, 'created_at')
      },
    },
  },
  {
    name: dbCollections.users,
    fields: ['displayName', 'billing_address', 'email', 'roles', 'created_at'],
    transform: {
      created_at: dataToIndex => {
        convertToUnixSeconds(dataToIndex, 'created_at')
      },
    },
  },
]

export const convertToUnixSeconds = (dataToIndex, field) => {
  dataToIndex[field] = dataToIndex[field].valueOf()
}

export const convertToGeoLoc = (dataToIndex, field) => {
  if (
    dataToIndex[field] &&
    dataToIndex[field].latitude &&
    dataToIndex[field].longitude
  ) {
    const _geoloc = {
      lat: dataToIndex[field].latitude,
      lng: dataToIndex[field].longitude,
    }
    delete dataToIndex[field]
    dataToIndex._geoloc = _geoloc
  }
}

export const processSnapshotData = async (data, collection) => {
  let dataToIndex = {}
  const collectionConfig = collections.find(c => c.name === collection)
  if (collectionConfig) {
    if (collectionConfig.fields) {
      // keep only the fields we've specified
      dataToIndex = pick(data, collectionConfig.fields)
    }
    if (collectionConfig.transform) {
      for (const fieldName in collectionConfig.transform) {
        if (
          collectionConfig.transform.hasOwnProperty(fieldName) &&
          dataToIndex.hasOwnProperty(fieldName)
        ) {
          // get the transform function from the config
          const fn = await collectionConfig.transform[fieldName]
          // perform the transform functions on the entire object
          await fn(dataToIndex)
        }
      }
    }
  }
  return dataToIndex
}

export const canViewQRBin = claims => {
  return (
    claims.role === 'admin' ||
    claims.role === 'dev' ||
    claims.role === 'collector'
  )
}

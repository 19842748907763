import {
  SET_REDIRECT,
  REQUEST_COLLECTION_CHANGE_STEP,
  RECORD_BIN_FULLNESS_CHANGE_STEP,
} from '../constants/flow.constants'

const initialState = {
  target: '',
  name: '',
  type: '',
  redirect: '',
  requestCollectionStep: 1,
  recordBinFullnessStep: 1,
}

const flow = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      }
    case REQUEST_COLLECTION_CHANGE_STEP:
      return {
        ...state,
        requestCollectionStep: action.payload,
      }
    case RECORD_BIN_FULLNESS_CHANGE_STEP:
      return {
        ...state,
        recordBinFullnessStep: action.payload,
      }
    default:
      return state
  }
}

export default flow

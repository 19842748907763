import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import auth from './auth.reducers'
import bin from './bin.reducers'
import flow from './flow.reducers'
import problem from './problem.reducers'
import binbag from './binbag.reducers'
import binCollections from './binCollections.reducers'

const rootReducer = combineReducers({
  auth,
  bin,
  flow,
  form,
  problem,
  binbag,
  binCollections
})

export default rootReducer

import {
    REPORT_PROBLEM_SUCCESS,
  } from '../constants/problem.constants'
  
  const initialState = {
    loading: false,
    error: false,
  }
  
  const problem = (state = initialState, action) => {
    switch (action.type) {
      case REPORT_PROBLEM_SUCCESS:
        return {
          ...state,
          error: false
        }
      default:
        return state
    }
  }
  
  export default problem

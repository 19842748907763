import {
  GET_BINS_FAILED,
  GET_BINS_SUCCEED,
  GET_BINS_PROCESSING,
  GET_BIN_FAILED,
  GET_BIN_SUCCEED,
  GET_BIN_PROCESSING,
  SAVE_BIN_FAILED,
  SAVE_BIN_SUCCEED,
  SAVE_BIN_PROCESSING,
  REQUEST_COLLECTION_SUCCEED,
  SHOW_BIN_ERROR,
  DISMISS_BIN_ERROR,
  SUBMIT_OBSERVATION_SUCCEED,
  GET_COLLECTION_REQUEST_AMOUNT_SUCCEED,
  SCANNED_BIN_QR_CODE,
  RESET_BIN_QR_CODE,
} from '../constants/bin.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  error: false,
  errorMessage: '',
  list: [],
  total: 0,
  record: {
    id: '',
    name: '',
    type: '',
    bin_location: {},
  },
  scannedBinQRCode: '',
  collectionRequestAmount: null,
  observations: {},
  requestedCollection: false,
  submittedObservation: false,
  collectionRequestResult: {},
  initialValues: {
    bin: '',
    fullness: 0,
    notes: '',
    user: '',
  },
}

const bin = (state = initialState, action) => {
  switch (action.type) {
    case GET_BINS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        errorMessage: action.payload.error,
      }
    case GET_BINS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case GET_BINS_SUCCEED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
      }
    }
    case GET_BIN_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
        error: true,
        errorMessage: action.payload,
      }
    case GET_BIN_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case GET_BIN_SUCCEED:
      return {
        ...state,
        errorMessage: '',
        error: false,
        fetching: false,
        fetched: true,
        record: action.payload.record,
      }
    case SAVE_BIN_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        error: true,
        errorMessage: action.payload.error,
      }
    case SAVE_BIN_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_BIN_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case SCANNED_BIN_QR_CODE:
      return {
        ...state,
        scannedBinQRCode: action.payload,
      }
    case RESET_BIN_QR_CODE:
      return {
        ...state,
        scannedBinQRCode: '',
      }
    case REQUEST_COLLECTION_SUCCEED:
      return {
        ...state,
        requestedCollection: true,
        collectionRequestResult: action.payload,
        error: false,
        errorMessage: '',
      }
    case GET_COLLECTION_REQUEST_AMOUNT_SUCCEED:
      return {
        ...state,
        collectionRequestAmount: action.payload.amount,
        error: false,
        errorMessage: '',
      }
    case SUBMIT_OBSERVATION_SUCCEED:
      return {
        ...state,
        submittedObservation: true,
        error: false,
        errorMessage: '',
      }
    case SHOW_BIN_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      }
    case DISMISS_BIN_ERROR:
      return {
        ...state,
        scannedBinQRCode: '',
        error: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default bin

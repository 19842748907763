import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HeaderMinimal from './HeaderMinimal'
import Header from './Header'

class DynamicHeader extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    children: PropTypes.element,
  }
  static defaultProps = {
    header: '',
    link: '/',
  }
  render() {
    const { header, link, children } = this.props
    switch (header) {
      case 'minimal':
        return <HeaderMinimal link={link}>{children}</HeaderMinimal>
      default:
        return <Header>{children}</Header>
    }
  }
}

export default DynamicHeader

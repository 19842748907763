import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import routes from '../routes/app'
import Layout from './layout'
import { auth, flow, bin as binActions } from '../actions'
import { canViewQRBin } from '../utils'

const RouteWithSubRoutes = route => (
  <div>
    <Route exact path={route.path} component={route.component} />
    {route.routes.map(sub => {
      return (
        <Route
          exact
          path={sub.path}
          component={sub.component}
          key={sub.action}
        />
      )
    })}
  </div>
)

const SwitchRoutes = props => (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.redirect)
        return (
          <Redirect
            from={prop.path}
            to={prop.to}
            key={key}
            history={props.history}
          />
        )
      if (prop.routes !== undefined) {
        return <RouteWithSubRoutes key={key} {...prop} />
      } else {
        return <Route path={prop.path} component={prop.component} key={key} />
      }
    })}
  </Switch>
)

SwitchRoutes.propTypes = {
  history: PropTypes.object.isRequired,
}

class App extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    getUserProfile: PropTypes.func.isRequired,
    loginAutomatically: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setRedirect: PropTypes.func.isRequired,
    redirect: PropTypes.string,
    target: PropTypes.string,
    name: PropTypes.string,
    claims: PropTypes.object.isRequired,
    listBinsForBinLocations: PropTypes.func.isRequired,
    binLocations: PropTypes.array.isRequired,
    scannedBinQRCode: PropTypes.string,
  }
  state = {
    mobileOpen: false,
  }
  constructor(props) {
    super(props)
    this.mainPanelRef = React.createRef()
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  componentDidMount() {
    auth.onAuthStateChanged(async user => {
      if (user) {
        await this.props.getUserProfile(user)
        await this.props.loginAutomatically()
        await this.props.listBinsForBinLocations(
          this.props.binLocations,
          canViewQRBin(this.props.claims) && this.props.scannedBinQRCode
        )
      } else {
        await this.props.logout()
        this.notLoggedIn()
      }
    })
  }
  loggedIn() {
    if (this.props.location.pathname === '/login') {
      if (this.props.redirect) {
        this.props.history.push(this.props.redirect)
      } else this.props.history.push('/dashboard')
    }
  }
  notLoggedIn() {
    this.props.history.push('/login')
  }
  render() {
    return (
      <Layout header="minimal" history={this.props.history}>
        <SwitchRoutes {...this.props} />
      </Layout>
    )
  }
}

function mapStateToProps({ auth, flow, bin }) {
  return {
    userId: auth.userId,
    isLoggedIn: auth.isLoggedIn,
    binLocations: auth.binLocations,
    claims: auth.claims,
    scannedBinQRCode: bin.scannedBinQRCode,
    ...flow,
  }
}

const mapDispatchToProps = {
  ...auth,
  ...flow,
  listBinsForBinLocations: binActions.listForBinLocations,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

import {
  GET_BINBAG_FAILED,
  GET_BINBAG_SUCCEED,
  GET_BINBAG_PROCESSING,
  SHOW_BINBAG_ERROR,
  DISMISS_BINBAG_ERROR,
  REQUEST_BINBAGS_SUCCEED,
  GET_BINBAG_ORDER_AMOUNT_SUCCEED,
} from '../constants/binbag.constants'

const initialState = {
  fetching: false,
  fetched: false,
  error: false,
  errorMessage: null,
  record: {},
  binbagOrderAmount: null,
}

const binbag = (state = initialState, action) => {
  switch (action.type) {
    case GET_BINBAG_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: null,
        error: true,
        errorMessage: action.payload.error,
      }
    case GET_BINBAG_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case GET_BINBAG_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        error: false,
        errorMessage: '',
      }
    case SHOW_BINBAG_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      }
    case DISMISS_BINBAG_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: '',
      }
    case REQUEST_BINBAGS_SUCCEED:
      return {
        ...state,
        error: false,
        errorMessage: '',
      }
    case GET_BINBAG_ORDER_AMOUNT_SUCCEED:
      return {
        ...state,
        binbagOrderAmount: action.payload.amount,
        error: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default binbag

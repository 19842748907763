import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'
import Img from 'react-image'
import highFive from '../images/binit-illustration-high-five.svg'
import kiss from '../images/binit-illustration-kiss.svg'
import person from '../images/binit-illustration-person.svg'
import thumbsUp from '../images/binit-illustration-thumbs-up-2.svg'

const thanksImages = [highFive, kiss, person, thumbsUp]

class ThanksStep extends Component {
  render() {
    const { history, text } = this.props
    const thanksImage = thanksImages[Math.floor(Math.random() * 4)]
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1">
            <div className="text-center">
              <h3 className="py-3">{text}</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <div className="mx-auto pt-3 mb-5 text-center">
              <Img
                src={thanksImage}
                style={{ maxWidth: '200px', maxHeight: '200px' }}
              />
            </div>
            <Button
              color="primary"
              onClick={() => history.push('/dashboard')}
              block
            >
              dashboard
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

ThanksStep.propTypes = {
  history: PropTypes.object.isRequired,
  collectionRequestResult: PropTypes.object,
  formSyncErrors: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
}

export default ThanksStep

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, FormFeedback } from 'reactstrap'
import classNames from 'classnames'

class ChunkyRadio extends Component {
  render() {
    const { input, meta, options } = this.props
    let iconClasses = {}
    let selectedColour = 'primary'
    let deselectedColour = 'white'
    let nC = ''
    let bC = ''
    let lC = ''

    return (
      <Row className="pb-3">
        {options.map(option => {
          const color =
            input.value.value === option.value ? selectedColour : deselectedColour
          const textColor = input.value.value === option.value ? 'white' : 'info'
          switch (color) {
            case 'primary':
              nC = classNames(['h5', 'p-3', 'align-middle'])
              lC = classNames(['h6'])
              bC = classNames([
                'text-white',
                'p-5',
                'binit-chunky-radio',
                'clearfix',
              ])
              break
            default:
              nC = classNames(['h5', 'text-info', 'p-3', 'align-middle'])
              lC = classNames(['h6'])
              bC = classNames(['p-5', 'binit-chunky-radio', 'clearfix'])
              iconClasses = { 'text-info': true }
              break
          }
          let iC = classNames(
            Object.assign(
              {
                h5: true,
                'float-left': true,
                'pt-3': true,
                'pr-3': true,
                [`text-${textColor}`]: true,
              },
              iconClasses,
              option.icon
            )
          )
          return (
            <Col key={option.value} sm="6" className="pb-3">
              <Button
                id={option.value}
                name={option.value}
                value={option.value}
                color={color}
                size="lg"
                block
                className={bC}
                onClick={() => input.onChange(option)}
              >
                {option.icon ? (
                  <i className={iC} onClick={() => input.onChange(option)} />
                ) : (
                  ''
                )}
                <span className={nC} onClick={() => input.onChange(option)}>
                  {option.name}
                </span>
                {option.label ? <span className={lC}>{option.label}</span> : ''}
              </Button>
            </Col>
          )
        })}
        <FormFeedback tooltip>{meta.error}</FormFeedback>
        <FormFeedback tooltip>{meta.warning}</FormFeedback>
      </Row>
    )
  }
}

ChunkyRadio.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  color: PropTypes.oneOf(['light', 'primary', 'secondary']),
  selectedColour: PropTypes.string,
  deselectedColour: PropTypes.string,
}

ChunkyRadio.defaultProps = {
  color: 'light',
}

export default ChunkyRadio

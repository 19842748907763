import App from '../components/App'
import Bin from '../components/Bin'
import BinBag from '../components/BinBag'
import Login from '../components/auth/Login'

const indexRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/bin/:bin',
    component: Bin,
  },
  {
    path: '/binbag/:binbag',
    component: BinBag,
  },
  { path: '/', component: App },
]

export default indexRoutes

import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

class DidYouKnow extends Component {
  render() {
    return (
      <Row>
        <Col xs="2">
          <i className="fas fa-info-circle fa-2x" />
        </Col>
        <Col>
          <p className="text-muted">
            <small>
              Did you know? You can also scan a QR code on a
              bin. Easy!
            </small>
          </p>
        </Col>
      </Row>
    )
  }
}

export default DidYouKnow

import {
    ADD_BIN_COLLECTION_SUCCESS,
  } from '../constants/binCollections.constants'
  
  const initialState = {
    loading: false,
    error: false,
  }
  
  const binCollections = (state = initialState, action) => {
    switch (action.type) {
      case ADD_BIN_COLLECTION_SUCCESS:
        return {
          ...state,
          error: false
        }
      default:
        return state
    }
  }
  
  export default binCollections

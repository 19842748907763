import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import Img from 'react-image'

import DynamicHeader from './DynamicHeader'
import '../../style/style.scss'
import 'rc-slider/assets/index.css'
import logo from '../../images/binit-logo-grey.png'
import Footer from './Footer'

const Layout = ({ children, header }) => (
  <Container fluid className="p-0 pb-5">
    <DynamicHeader header={header} link={'/'}>
      <Img src={logo} style={{ height: '40px' }} />
    </DynamicHeader>
    <div>{children}</div>
    <Footer />
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
}

export default Layout

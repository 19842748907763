import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'reactstrap'
import { Field, propTypes } from 'redux-form'

import CustomTextAreaInput from '../inputs/CustomTextAreaInput'
import DidYouKnow from '../DidYouKnow'

class NotesStep extends Component {
  static propTypes = {
    ...propTypes,
    changeStep: PropTypes.func.isRequired,
    list: PropTypes.array,
    selected: PropTypes.object,
    business: PropTypes.object,
    formSyncErrors: PropTypes.object.isRequired,
  }

  render() {
    const {
      submitting,
      business,
      formValues,
      formSyncErrors: { notes: notesError },
      handleSubmit,
    } = this.props
    let selectedBin = ''
    if (formValues && formValues.bin && formValues.bin.name) {
      selectedBin = formValues.bin.name
    }
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
            <h3>Tell us what is wrong</h3>
            <p>
              {selectedBin} / {business.name}
            </p>
          </Col>
        </Row>
        <Row className="p-3 p-md-5 pt-3">
          <Col lg={{ size: 10, offset: 1 }}>
            <Field
              id="notes"
              name="notes"
              component={CustomTextAreaInput}
              rows="5"
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Button
              color="primary"
              className="px-5"
              onClick={handleSubmit}
              disabled={Boolean(notesError) || submitting}
              block
            >
              {submitting ? (
                <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
              ) : (
                'confirm'
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="pt-5"
          >
            <DidYouKnow />
          </Col>
        </Row>
      </>
    )
  }
}

export default NotesStep

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
const Handle = Slider.Handle

const handle = props => {
  const { value, ...restProps } = props
  const classes = value && value >= 100 ? 'danger' : 'normal'
  return (
    <Handle
      value={value}
      {...restProps}
      dragging={restProps.dragging ? 'dragging' : undefined}
      className={`${restProps.className} ${classes}`}
    />
  )
}
handle.propTypes = {
  value: PropTypes.number.isRequired,
}

class ChunkySlider extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    marks: PropTypes.object,
    step: PropTypes.number,
    color: PropTypes.string.isRequired,
    warningColor: PropTypes.string.isRequired,
  }

  static defaultProps = {
    min: 0,
    max: 100,
    marks: {},
    step: null,
    color: 'info',
    warningColor: 'danger',
  }

  render() {
    const {
      input,
      className,
      min,
      max,
      marks,
      step,
      color,
      warningColor,
    } = this.props
    const valueColor =
      typeof input.value === 'number' && input.value > 99 ? warningColor : color
    return (
      <>
        <Slider
          {...input}
          className={className}
          min={min}
          max={max}
          marks={marks}
          step={step}
          dots={false}
          handle={handle}
        />
        <h3 className={`text-${valueColor} pt-4 d-md-none`}>
          {typeof input.value === 'number' ? `${input.value}%` : '-'}
        </h3>
      </>
    )
  }
}

export default ChunkySlider

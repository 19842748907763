import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import { Alert, Container, Row, Col, Button } from 'reactstrap'

import SEO from '../SEO'
import CustomTextInput from '../inputs/CustomTextInput'

import { auth, flow } from '../../actions'

const form = 'login'

const validate = values => {
  const errors = {}
  if (!values.code) {
    errors.code = 'You must specify a code to log in'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await dispatch(auth.loginWithCode(values.code))
    if (props.redirect) {
      const redirect = props.redirect
      dispatch(flow.setRedirect(''))
      props.history.push(redirect)
    } else props.history.push('/')
  } catch (err) {
    throw new SubmissionError({
      _error: `Sorry, looks like that code isn't valid`,
    })
  }
}

class Login extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    redirect: PropTypes.string,
  }
  render() {
    const {
      handleSubmit,
      submitting,
      error,
      invalid,
      pristine,
      reset,
      submitFailed,
    } = this.props
    return (
      <>
        <SEO title="Login - Binit" keywords={['binit', 'recyling', 'waste']} />
        <Container fluid={true} className="bg-light pb-5">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={{ size: 4, offset: 4 }}>
                <Row className="p-1 p-md-5 text-center">
                  <Col>
                    <h1>log in to your binit account</h1>
                  </Col>
                </Row>
                <Row className="">
                  <Col>
                    <Field
                      type="password"
                      name="code"
                      component={CustomTextInput}
                      placeholder="binit code"
                      disabled={submitting}
                    />
                    <Button
                      type="submit"
                      color="primary"
                      disabled={invalid || submitting || pristine}
                      block
                    >
                      {submitting ? (
                        <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
                      ) : (
                        'confirm'
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-5">
                    <p>
                      Forgotten your Binit code? Please check our sticker or
                      give us a call:
                    </p>
                    <p>01392 927244</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
          <Alert
            color="danger"
            isOpen={submitFailed && Boolean(error)}
            toggle={() => reset()}
            className="fixed-bottom m-5"
          >
            {error}
          </Alert>
        </Container>
      </>
    )
  }
}

const mapStateToProps = ({ auth, flow }) => {
  return {
    initialValues: auth.initialValues,
    redirect: flow.redirect,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: false,
  })(Login)
)

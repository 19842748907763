import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap'

class CustomTextAreaInput extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
  }

  render() {
    const {
      meta: { error } = {},
      input: { ...inputProps },
      ...props
    } = this.props
    return (
      <FormGroup className="pb-3">
        {props.label ? (
          <Label for={props.id}>
            <h5 className={`text-${props.color}`}>{props.label}</h5>
          </Label>
        ) : (
          ''
        )}
        <Input
          type="textarea"
          className="text-info border-info"
          {...inputProps}
          {...props}
        />
        <FormFeedback tooltip>{error}</FormFeedback>
      </FormGroup>
    )
  }
}

export default CustomTextAreaInput

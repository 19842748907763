import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  SubmissionError,
  getFormSyncErrors,
  getFormValues,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import { Container, Alert } from 'reactstrap'
import { StripeProvider, Elements } from 'react-stripe-elements'

import Wizard from './Wizard'
import SelectBinStep from './SelectBinStep'
import SelectDateStep from './request-collection/SelectDateStep'
import PaymentStep from './request-collection/PaymentStep'
import ThanksStep from './request-collection/ThanksStep'

import { bin as binActions } from '../actions/bin.actions'
import { flow } from '../actions/flow.actions'
import ErrorStep from './ErrorStep'

const form = 'request-a-collection'

const validate = values => {
  const errors = {}
  if (!values.bin || !values.bin.value) {
    errors.bin = 'Required'
  }
  if (!values.requested_pickup_date) {
    errors.requested_pickup_date = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    const collectionRequest = {
      ...values,
      // have to add in business and user
      business: props.business.id,
      user: props.user.id,
      on_account: props.business.has_account,
    }
    await props.requestCollection(collectionRequest)
    props.changeStep(4)
  } catch (err) {
    setTimeout(props.hideError, 6000)
    // go to the error page
    props.changeStep(5)
    throw new SubmissionError({
      _error: 'Problem purchasing, please try again later',
    })
  }
}

/**
 * REQUEST A COLLECTION
 *
 * Request a collection flow
 */
class RequestCollection extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    step: PropTypes.number.isRequired,
    collectionRequestResult: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
    binLocations: PropTypes.array.isRequired,
    business: PropTypes.object,
    errorMessage: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    formSyncErrors: PropTypes.object.isRequired,
  }
  state = {
    alertOpen: false,
    stripe: null,
  }
  componentDidMount() {
    this.props.changeStep(1)
  }
  handleClickAction = link => {
    this.props.history.push(link)
  }
  render() {
    const { handleSubmit, step, errorMessage, submitFailed } = this.props
    return (
      <Container>
        <form onSubmit={handleSubmit}>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
            <Elements
              fonts={[
                { cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans' },
              ]}
            >
              <Wizard
                step={step}
                steps={[
                  <SelectBinStep {...this.props} key={1} />,
                  <SelectDateStep {...this.props} key={2} />,
                  <PaymentStep {...this.props} key={3} />,
                  <ThanksStep {...this.props} key={4} />,
                  <ErrorStep {...this.props} key={5} />,
                ]}
              />
            </Elements>
          </StripeProvider>
        </form>
        <Alert
          color="primary"
          isOpen={submitFailed}
          // @TODO: fix error dismissal
          toggle={() => {}}
          className="fixed-bottom m-5"
        >
          {errorMessage}
        </Alert>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const { auth, bin, flow } = state
  return {
    step: flow.requestCollectionStep,
    user: auth.user,
    business: auth.business,
    binLocations: auth.binLocations,
    bin,
    selectedBin: bin.record,
    claims: auth.claims,
    collectionRequestResult: bin.collectionRequestResult,
    collectionRequestAmount: bin.collectionRequestAmount,
    formSyncErrors: getFormSyncErrors(form)(state),
    formValues: getFormValues(form)(state),
    errorMessage: bin.errorMessage,
    initialValues: {
      bin: {
        name: bin.record ? bin.record.name || '' : '',
        value: bin.record ? bin.record.id || '' : '',
      },
    },
  }
}

const mapDispatchToProps = {
  ...binActions,
  changeStep: flow.requestCollectionChangeStep,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: false,
  })(RequestCollection)
)

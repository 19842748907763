import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'reactstrap'
import { Field, propTypes as reduxFormPropTypes } from 'redux-form'

import ChunkySelect from '../inputs/ChunkySelect'

class QuantityStep extends Component {
  state = {
    gettingQuote: false,
  }
  static propTypes = {
    ...reduxFormPropTypes,
    step: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
    bin: PropTypes.object.isRequired,
    business: PropTypes.object,
    formSyncErrors: PropTypes.object.isRequired,
  }

  changeStep = async () => {
    this.setState({ gettingQuote: true })
    await this.props.getBinbagOrderAmount(this.props.formValues)
    this.props.change('amount', this.props.binbagOrderAmount)
    this.props.changeStep(this.props.step + 1)
  }

  render() {
    const {
      formSyncErrors: { quantity: quantityError, type: typeError },
    } = this.props
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
            <h3>Select a quantity</h3>
          </Col>
        </Row>
        <Row className="px-1 px-md-5 pt-3">
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Field
              id="quantity"
              name="quantity"
              title="Select a quantity"
              color="primary"
              options={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              component={ChunkySelect}
            />
          </Col>
        </Row>
        <Row className="px-1 px-md-5 pt-3">
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Field
              id="type"
              name="type"
              title="Select a type"
              color="primary"
              options={['rubbish', 'recycling']}
              component={ChunkySelect}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Button
              color="primary"
              className="px-5"
              onClick={() => this.changeStep()}
              disabled={Boolean(quantityError) || Boolean(typeError)}
              block
            >
              {this.state.gettingQuote ? (
                <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
              ) : (
                'Next step'
              )}
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default QuantityStep

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Alert, Container, Row, Col } from 'reactstrap'

import { bin as binActions } from '../actions'

/**
 * BIN COMPONENT
 *
 * Just collects data on the state and redirects to Dashboard
 */
class Bin extends Component {
  state = {
    alertOpen: false,
  }
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    setRedirect: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    targetBin: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  async componentDidMount() {
    if (this.props.match.params.bin) {
      await this.props.targetBin(this.props.match.params.bin)
    }
  }
  render() {
    const { history, error, errorMessage } = this.props
    return (
      <Container>
        <Row className="p-8">
          <Col className="text-center">
            <h1 className="mb-5">…finding that bin!</h1>
            <i className="fas fa-circle-notch fa-spin fa-3x text-black" />
          </Col>
        </Row>
        <Alert
          color="danger"
          isOpen={error}
          toggle={() => history.push('/dashboard')}
          className="fixed-bottom m-5"
        >
          {errorMessage}
        </Alert>
      </Container>
    )
  }
}

const mapStateToProps = ({ bin, auth }) => {
  return { ...bin, isLoggedIn: auth.isLoggedIn }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    targetBin: async name => {
      try {
        await dispatch(binActions.scannedBinQRCode(name))
        ownProps.history.push('/dashboard')
      } catch (err) {
        ownProps.history.push('/dashboard')
      }
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bin)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'reactstrap'
import { Field, propTypes } from 'redux-form'

import ChunkySlider from '../inputs/ChunkySlider'
import CustomTextAreaInput from '../inputs/CustomTextAreaInput'
import DidYouKnow from '../DidYouKnow'

class RecordFullness extends Component {
  static propTypes = {
    ...propTypes,
    step: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
    bin: PropTypes.object.isRequired,
    selected: PropTypes.object,
    business: PropTypes.object,
    formValues: PropTypes.object,
    formSyncErrors: PropTypes.object,
  }
  render() {
    const { business, invalid, submitting, formValues } = this.props
    const { bin } = formValues ? formValues : { bin: { name: '' } }
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
            <h3>how full is this bin?</h3>
            <p>
              {bin.name} / {business.name}
            </p>
          </Col>
        </Row>
        <Row className="p-3 p-md-5 pt-3">
          <Col lg={{ size: 8, offset: 2 }} className="text-center">
            <Field
              id="fullness"
              name="fullness"
              component={ChunkySlider}
              className="binit-chunky-slider"
              min={0}
              max={120}
              marks={{
                0: {
                  style: {
                    fontFamily: 'GalanoGrotesque-Bold',
                    fontSize: '1.5625rem',
                  },
                  label: '0%',
                },
                10: '10%',
                20: '20%',
                30: '30%',
                40: '40%',
                50: {
                  style: {
                    fontFamily: 'GalanoGrotesque-Bold',
                    fontSize: '1.5625rem',
                  },
                  label: '50%',
                },
                60: '60%',
                70: '70%',
                80: '80%',
                90: '90%',
                100: {
                  style: {
                    fontFamily: 'GalanoGrotesque-Bold',
                    fontSize: '1.5625rem',
                    color: '#FF263C',
                  },
                  label: '100%',
                },
                110: { style: {}, label: '' },
                120: { style: { color: '#FF263C' }, label: 'overflowing' },
              }}
              step={null}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <Field
              id="notes"
              name="notes"
              type="textarea"
              color="info"
              label="notes"
              component={CustomTextAreaInput}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="text-center"
          >
            <Button
              type="submit"
              color="primary"
              className="px-5"
              disabled={invalid || submitting}
              block
            >
              {submitting ? (
                <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
              ) : (
                'confirm'
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            className="pt-5"
          >
            <DidYouKnow />
          </Col>
        </Row>
      </>
    )
  }
}

export default RecordFullness

import {
  LOGIN_WITH_CODE_SUCCESS,
  LOGIN_WITH_CODE_FAILURE,
  SET_USER_PROFILE,
  SET_CUSTOM_USER_CLAIMS,
  LOGOUT,
  LOADING_PROFILE_REQUEST,
  LOADING_PROFILE_SUCCEED,
  LOADING_PROFILE_FAILURE,
} from '../constants/auth.constants'

const initialState = {
  user: {
    id: '',
    displayName: '',
    email: '',
    role: '',
  },
  business: {
    name: '',
  },
  binLocations: [],
  isLoggedIn: false,
  claims: {},
  initialValues: {
    code: '',
  },
  loadingProfile: false,
  loadedProfile: false,
  loadingProfileFailed: false,
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_WITH_CODE_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      }
    case LOGIN_WITH_CODE_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload,
        isLoggedIn: false,
      }
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        user: action.payload.user,
        business: action.payload.business,
        binLocations: action.payload.binLocations,
      }
    }
    case SET_CUSTOM_USER_CLAIMS:
      return {
        ...state,
        claims: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    case LOADING_PROFILE_REQUEST:
      return {
        ...state,
        loadedProfile: false,
        loadingProfile: true,
        loadingProfileFailed: false,
      }
    case LOADING_PROFILE_SUCCEED:
      return {
        ...state,
        loadedProfile: true,
        loadingProfile: false,
        loadingProfileFailed: false,
      }
    case LOADING_PROFILE_FAILURE:
      return {
        ...state,
        loadedProfile: false,
        loadingProfile: false,
        loadingProfileFailed: false,
      }
    default:
      return state
  }
}

export default auth

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormFeedback, CustomInput } from 'reactstrap'

class ChunkySelect extends Component {
  state = {
    dropdownOpen: false,
  }
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired,
  }
  static defaultProps = {
    id: 'chunky-select',
    name: 'chunky-select',
    color: '',
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const {
      input,
      meta: { valid, invalid, error },
      color,
      title,
      id,
      name,
      options,
    } = this.props
    return (
      <FormGroup className="pb-3">
        <CustomInput
          type="select"
          id={id}
          color={color}
          valid={valid}
          invalid={invalid}
          name={name}
          {...input}
        >
          <option value="">{title}</option>
          {options.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </CustomInput>
        <FormFeedback valid={valid}>{error}</FormFeedback>
      </FormGroup>
    )
  }
}

export default ChunkySelect

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import classNames from 'classnames'

import { filterActionsByClaims } from '../utils'
import { bin as binActions } from '../actions'

const actionOptions = [
  {
    name: 'request a collection',
    link: '/request-a-collection',
    value: 'collection_requests',
    icon: { fas: true, 'fa-truck': true, 'fa-lg': true },
    deselectedColor: 'primary',
  },
  {
    name: 'confirm successful collection',
    link: '/confirm-successfull-collection',
    value: 'bin_collections',
    icon: { fas: true, 'fa-check-circle': true, 'fa-lg': true },
    deselectedColor: 'primary',
  },
  {
    name: 'order bin bags',
    link: '/order-binbags',
    value: 'binbag_requests',
    icon: {
      fas: true,
      'fa-box-open': true,
      'fa-lg': true,
    },
  },
  {
    name: 'report a problem',
    link: '/report-a-problem',
    value: 'problems',
    icon: {
      fas: true,
      'fa-exclamation-circle': true,
      'fa-lg': true,
    },
  },
  {
    name: 'record bin fullness',
    link: '/record-bin-fullness',
    value: 'observations',
    icon: {
      fas: true,
      'fa-sliders-h': true,
      'fa-lg': true,
    },
  },
]

/**
 * DASHBOARD
 *
 * Landing location for everyone
 */
class Dashboard extends Component {
  state = {
    alertOpen: false,
  }
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    business: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    claims: PropTypes.object,
    loadingProfile: PropTypes.bool.isRequired,
    loadedProfile: PropTypes.bool.isRequired,
    target: PropTypes.string,
    name: PropTypes.string,
    getBin: PropTypes.func.isRequired,
    dismissError: PropTypes.func.isRequired,
    bin: PropTypes.object,
  }
  handleClickAction = link => {
    this.props.history.push(link)
  }
  render() {
    const {
      business,
      claims,
      loadingProfile,
      loadedProfile,
      isLoggedIn,
      dismissError,
      bin,
    } = this.props
    const opts = filterActionsByClaims(actionOptions, claims)

    return (
      <Container>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="pt-5 pb-1 text-center">
            <h3>welcome back!</h3>
            <p>{business}</p>
          </Col>
        </Row>
        <Row className="p-1 p-md-5 pt-3">
          <Col lg={{ size: 8, offset: 2 }} className="text-center">
            <Row className="pb-3 d-flex">
              {opts.length > 0 ? (
                opts.map(option => {
                  let iC = classNames(
                    Object.assign(
                      {
                        'pt-3': true,
                      },
                      option.icon
                    )
                  )
                  return (
                    <Col key={option.name} sm="6" className="pb-3">
                      <Button
                        id={option.link}
                        color="primary"
                        size="lg"
                        block
                        className="p-5 binit-chunky-radio clearfix h-100"
                        onClick={() => this.handleClickAction(option.link)}
                      >
                        {option.icon ? <i className={iC} /> : ''}
                        <p className="h5 p-3 align-middle text-white">
                          {option.name}
                        </p>
                        {option.label ? (
                          <span className="h6">{option.label}</span>
                        ) : (
                          ''
                        )}
                      </Button>
                    </Col>
                  )
                })
              ) : !isLoggedIn || (loadingProfile && !loadedProfile) ? (
                <Col className="text-center">
                  <i className="fas fa-circle-notch fa-spin fa-5x" />
                </Col>
              ) : (
                <h3>Sorry, no actions available for your role</h3>
              )}
            </Row>
          </Col>
        </Row>
        <Alert
          color="danger"
          isOpen={bin.error}
          toggle={() => dismissError()}
          className="fixed-bottom m-5"
        >
          {bin.errorMessage}
        </Alert>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth, flow, bin }) => {
  return {
    claims: auth.claims,
    business: (auth.business && auth.business.name) || '',
    isLoggedIn: auth.isLoggedIn,
    loadingProfile: auth.loadingProfile,
    loadedProfile: auth.loadedProfile,
    ...flow,
    bin,
  }
}

const mapDispatchToProps = {
  getBin: binActions.get,
  dismissError: binActions.dismissError,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import { binbag } from '../actions'

class BinBag extends Component {
  state = {
    alertOpen: false,
  }
  componentDidMount() {
    if (this.props.match.params.binbag) {
      this.props.targetBinBag(this.props.match.params.binbag)
    }
  }
  render() {
    return (
      <Container>
        <Row className="p-8">
          <Col className="text-center">
            <h1 className="mb-5">…finding that bin bag!</h1>
            <i className="fas fa-circle-notch fa-spin fa-3x text-black" />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    targetBinBag: async name => {
      await dispatch(binbag.get(name))
      //await dispatch(flow.setTarget({ target: 'bin' }))
      //ownProps.history.push('/dashboard')
    },
  }
}

BinBag.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  targetBinBag: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BinBag)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Container, Row, Col } from 'reactstrap'

import { auth } from '../../actions/auth.actions'

import packageJSON from '../../../package.json'

class Footer extends Component {
  state = {
    isOpen: true,
  }

  static propTypes = {
    logout: PropTypes.func.isRequired,
  }

  logout = async () => {
    await this.props.logout()
  }

  render() {
    return (
      <Container id="footer" fluid>
        <Container className="pb-0 align-right">
          <Row>
            <Col xs={{size: 6, offset: 0}} md={{size: 2, offset: 8}}>
              <Button color="link" onClick={() => this.logout()}>
                logout
              </Button>
            </Col>
            <Col xs="6" md="2">
              <p className="text-muted text-right text-monospace font-weight-light p-3">
                <small>v{packageJSON.version}</small>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = { ...auth }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)

import { bin as binAPI } from '../api/modules/bin.api'
import {
  SHOW_BIN_ERROR,
  DISMISS_BIN_ERROR,
  SCANNED_BIN_QR_CODE,
} from '../constants/bin.constants'

export const bin = {
  listForBinLocations(binLocations, binQRCode) {
    return dispatch => {
      return dispatch(binAPI.listForBinLocations(binLocations, binQRCode))
    }
  },
  scannedBinQRCode(name) {
    return dispatch => {
      return dispatch({
        type: SCANNED_BIN_QR_CODE,
        payload: name,
      })
    }
  },
  get(name) {
    return dispatch => {
      return dispatch(binAPI.getByName(name))
    }
  },
  requestCollection(collectionRequest) {
    return dispatch => {
      return dispatch(binAPI.requestCollection(collectionRequest))
    }
  },
  getCollectionRequestAmount(request) {
    return dispatch => {
      return dispatch(binAPI.getCollectionRequestAmount(request))
    }
  },
  submitObservation(observation) {
    return dispatch => {
      return dispatch(binAPI.submitObservation(observation))
    }
  },
  showError(errorMessage) {
    return dispatch => {
      return dispatch({
        type: SHOW_BIN_ERROR,
        payload: errorMessage,
      })
    }
  },
  dismissError() {
    return dispatch => {
      return dispatch({
        type: DISMISS_BIN_ERROR,
      })
    }
  },
}

import { binbag as binbagAPI } from '../api'
import {
  SHOW_BINBAG_ERROR,
  DISMISS_BINBAG_ERROR,
} from '../constants/binbag.constants'

export const binbag = {
  get(name) {
    return dispatch => {
      return dispatch(binbagAPI.getByName(name))
    }
  },
  requestBinbags(values) {
    return dispatch => {
      return dispatch(binbagAPI.requestBinbags(values))
    }
  },
  getBinbagOrderAmount(request) {
    return dispatch => {
      return dispatch(binbagAPI.getBinbagOrderAmount(request))
    }
  },
  showError(errorMessage) {
    return dispatch => {
      return dispatch({
        type: SHOW_BINBAG_ERROR,
        payload: errorMessage,
      })
    }
  },
  dismissError() {
    return dispatch => {
      return dispatch({
        type: DISMISS_BINBAG_ERROR,
      })
    }
  },
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  getFormSyncErrors,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import { Alert, Container } from 'reactstrap'
import { StripeProvider, Elements } from 'react-stripe-elements'

import Wizard from './Wizard'

import QuantityStep from './order-binbags/QuantityStep'
import PaymentStep from './order-binbags/PaymentStep'
import ThanksStep from './order-binbags/ThanksStep'

import { flow as flowActions } from '../actions/flow.actions'
import { binbag as binbagActions } from '../actions/binbag.actions'
import { auth as authActions } from '../actions/auth.actions'

import ErrorStep from './ErrorStep'

const formName = 'order-binbags'

const validate = values => {
  const errors = {}
  if (!values.quantity) {
    errors.quantity = 'Required'
  }
  if (!values.type) {
    errors.type = 'Required'
  }
  if (!values.businessSite) {
    errors.businessSite = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    const binbagRequest = {
      ...values,
      business: props.business.id,
      user: props.user.id,
      on_account: props.business.has_account,
    }
    await props.requestBinbags(binbagRequest)
    props.changeStep(3)
  } catch (err) {
    setTimeout(props.hideError, 6000)
    props.changeStep(4)
    throw new SubmissionError({
      _error: 'Problem reporting problem, please try again later',
    })
  }
}

class OrderBinBags extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    step: PropTypes.number.isRequired,
    collectionRequestResult: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
    binLocations: PropTypes.array.isRequired,
    business: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    formSyncErrors: PropTypes.object.isRequired,
    binbag: PropTypes.object.isRequired,
    dismissError: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.changeStep(1)
  }
  handleClickAction = link => {
    this.props.history.push(link)
  }
  render() {
    const { handleSubmit, step, dismissError, binbag } = this.props
    return (
      <>
        <Container>
          <form onSubmit={handleSubmit}>
            <StripeProvider
              apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            >
              <Elements
                fonts={[
                  {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
                  },
                ]}
              >
                <Wizard
                  step={step}
                  steps={[
                    <QuantityStep {...this.props} key={1} />,
                    <PaymentStep {...this.props} key={2} />,
                    <ThanksStep {...this.props} key={3} />,
                    <ErrorStep {...this.props} key={4} />,
                  ]}
                />
              </Elements>
            </StripeProvider>
          </form>
        </Container>
        <Alert
          color="danger"
          isOpen={binbag.error}
          toggle={() => dismissError()}
          className="fixed-bottom m-5"
        >
          {binbag.errorMessage}
        </Alert>
      </>
    )
  }
}

const mapStateToProps = state => {
  const { auth, bin, binbag, flow, form } = state
  return {
    step: flow.requestCollectionStep,
    user: auth.user,
    business: auth.business,
    binLocations: auth.binLocations,
    bin,
    binbag,
    binbagOrderAmount: binbag.binbagOrderAmount,
    claims: auth.claims,
    collectionRequestResult: bin.collectionRequestResult,
    formSyncErrors: getFormSyncErrors(formName)(state),
    formValues: form[formName] ? form[formName].values : null,
    initialValues: {
      quantity: 0,
      type: null,
    },
  }
}

const mapDispatchToProps = {
  ...binbagActions,
  changeStep: flowActions.requestCollectionChangeStep,
  getUserProfile: authActions.getUserProfile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formName,
    onSubmit,
    validate,
    initialValues: {
      quantity: null,
      businessSite: null,
    },
    enableReinitialize: true,
  })(OrderBinBags)
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import { Container } from 'reactstrap'

import Wizard from './Wizard'
import SelectBinStep from './SelectBinStep'
import NotesStep from './report-a-problem/NotesStep'
import ThanksStep from './report-a-problem/ThanksStep'
import ErrorStep from './ErrorStep'

import { flow } from '../actions/flow.actions'
import { problem } from '../actions/problem.actions'

const form = 'report-a-problem'

const validate = values => {
  const errors = {}
  if (!values.bin) {
    errors.bin = 'Required'
  }
  if (!values.notes) {
    errors.notes = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    const problemRequest = {
      ...values,
      business: props.business.id,
      user: props.user.id,
    }
    await dispatch(problem.reportProblem(problemRequest))
    props.changeStep(3)
  } catch (err) {
    setTimeout(props.hideError, 6000)
    throw new SubmissionError({
      _error: 'Problem reporting problem, please try again later',
    })
  }
}

class ReportProblem extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    step: PropTypes.number.isRequired,
    collectionRequestResult: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
    listBinsForUser: PropTypes.func.isRequired,
    binLocations: PropTypes.array.isRequired,
    business: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    error: PropTypes.string,
    formSyncErrors: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.changeStep(1)
  }
  handleClickAction = link => {
    this.props.history.push(link)
  }
  render() {
    const { handleSubmit, step } = this.props
    return (
      <Container>
        <form onSubmit={handleSubmit}>
          <Wizard
            step={step}
            steps={[
              <SelectBinStep {...this.props} key={1} />,
              <NotesStep {...this.props} key={2} />,
              <ThanksStep {...this.props} key={3} />,
              <ErrorStep {...this.props} key={4} />,
            ]}
          />
        </form>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const { auth, bin, flow } = state
  return {
    step: flow.requestCollectionStep,
    user: auth.user,
    business: auth.business,
    binLocations: auth.binLocations,
    bin,
    selectedBin: bin.record,
    claims: auth.claims,
    collectionRequestResult: bin.collectionRequestResult,
    formValues: getFormValues(form)(state),
    formSyncErrors: getFormSyncErrors(form)(state),
    initialValues: {
      bin: {
        name: bin.record ? bin.record.name || '' : '',
        value: bin.record ? bin.record.id || '' : '',
      },
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeStep: step => {
      dispatch(flow.requestCollectionChangeStep(step))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: true,
  })(ReportProblem)
)
